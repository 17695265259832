<template>
  <div class="car-detail">
    <!-- 基础信息 -->
    <div class="basic-info">
      <div class="hr">配置信息</div>
      <div class="card">
        <div class="info">{{ detailInfo.confOrderId }}</div>
        <div class="info">
          <span class="val">{{ detailInfo.materialJson.seriesName }} {{ detailInfo.materialJson.modelName }}</span>
          <span v-if="detailInfo.status === 5" class="tag">已生效</span>
          <span v-if="detailInfo.status === 10" class="tag">已失效</span>
        </div>
        <div class="info">
          <span class="label">{{$t('外饰：')}}</span>
          <span class="val">{{ detailInfo.materialJson.colourName }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('内饰：')}}</span>
          <span class="val">{{ detailInfo.materialJson.interiorName }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('选装：')}}</span>
          <div class="val">
            <div v-for="(optionsItem, index) in (detailInfo.materialJson.optionList || [])" :key="index">
              {{ `${detailInfo.materialJson.optionList.length > 1 ? index+1 + '、' : ''}${ detailInfo.id || entryType === 'operate' ? optionsItem.optionsName : optionsItem.dictName || ''}` }}
            </div>
          </div>
        </div>
        <div class="info">
          <span class="label">车辆类型:</span>
          <span class="val">{{ detailInfo.specialVehicleType === '40551002' ? '质损车' : detailInfo.specialVehicleType === '40551003' ? '老龄车' : detailInfo.specialVehicleType === '40551004' ? 'Ni80车型' : '' }}</span>
        </div>
        <!--  详情展示 -->
        <template v-if="detailInfo.id">
          <div class="info">
            <span class="label">交付门店：</span>
            <span class="val">{{ detailInfo.deliveryDealerName }}</span>
          </div>
          <div class="info">
            <span class="label">创建人：</span>
            <span class="val">{{ detailInfo.salesDealerName }} | {{ detailInfo.salesName }}</span>
          </div>
          <div class="info">
            <span class="label">创建时间：</span>
            <span class="val">{{ detailInfo.createTime }}</span>
          </div>
          <div class="info">
            <span class="label">有效期至：</span>
            <span class="val">{{ detailInfo.planInvalidTime }}</span>
          </div>
          <!-- 只在已生效的状态下显示   -->
          <div v-if="detailInfo.status === 5" class="info">
            <span class="label">预留VIN：</span>
            <span class="val">{{ detailInfo.vin }}</span>
          </div>
          <!-- 已关联订单 -->
          <template v-if="detailInfo.status === 15">
            <div class="info">
              <span class="label">关联订单编号：</span>
              <div :key="index" class="val">
                <div v-for="(item, index) in detailInfo.orders" :key="index" style="display: flex;justify-content: space-between;">
                  <span>{{ item.appOrderId }}</span>
                  <span style="color: #c59e25;cursor: pointer;" @click="goOrdeDetail(item)">查看订单详情>></span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- 创建时展示/详情不展示 -->
    <div v-if="!detailInfo.id" class="basic-info">
      <div class="hr">申请信息</div>
      <div class="card">
        <div class="info">
          <span class="label">{{$t('申请人')}}：</span>
          <span class="val">{{ detailInfo.id ? detailInfo.salesDealerName : dealerName }} | {{ detailInfo.id ? detailInfo.salesName : userInfo.name }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('交付门店')}}：</span>
          <span class="val">{{ detailInfo.id ? detailInfo.deliveryDealerName : detailInfo.deliveryStoreName }}</span>
        </div>
      </div>
    </div>
    <!-- 在配置单已失效时显示 -->
    <div v-if="detailInfo.status === 10" class="basic-info">
      <div class="hr">操作记录</div>
      <div class="card">
        <div class="info">
          <span class="label">失效时间：</span>
          <span class="val">{{ detailInfo.invalidTime }}</span>
        </div>
        <div class="info">
          <span class="label">操作人：</span>
          <span class="val">{{ detailInfo.invalidByName }}</span>
        </div>
        <div class="info">
          <span class="label">备注：</span>
          <span v-if="detailInfo.invalidById === '0'" class="val">超时系统自动失效</span>
        </div>
      </div>
    </div>
    <div v-if="detailInfo.status === 5" class="basic-info">
      <div class="hr">下单二维码</div>
      <div class="card">
        <div class="qrcode-box">
          <div class="qrcode">
            <div>
              <van-image width="10rem" height="10rem" :src="detailInfo.qrCodeUrl">
                <template v-slot:error>加载失败</template>
              </van-image>
              <div class="btns">
                <span class="btn" @click="downloadImg">{{ judgePc ? '长按二维码可分享或保存' : '保存图片' }}</span>
              </div>
            </div>
            <div class="valid-time">
              <div>有效期至</div>
              <div>{{ detailInfo.planInvalidTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!detailInfo.id" class="error-tips">注：创建配置单后，会在一定时间内为您预留车辆资源（预留的资源可能不是您查询到的库存车，具体以OTD车辆资源分配规则而定）；</div>
    <div v-if="!detailInfo.id" class="error-tips">请及时将下单二维码提供至客户下单，超过时限二维码将会失效同时会释放车辆资源</div>
    <div v-if="detailInfo.status === 5" class="error-tips">请及时将下单二维码提供至客户下单，超过有效期二维码将会失效同时会释放车辆资源</div>
    <!-- 创建-取消/立即创建 -->
    <!-- 详情-取消/发送二维码至客户下单 -->
    <div v-if="!detailInfo.id" class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('取消')}}</van-button>
      <van-button class="btn submit" type="submit" @click="create">立即创建</van-button>
    </div>
    <div v-if="detailInfo.status === 5" class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="cancelOrder">取消配置单</van-button>
      <van-button class="btn submit long-btn" type="submit" @click="shareImg">发送二维码至客户下单</van-button>
    </div>
  </div>
  </template>
<script>
import { mapGetters } from 'vuex'
import loading from '@/utils/loading'
import { createSpecialOrder, specialOrderDetail, invalidOrder } from '@/services/specialCar.js'
import wxSDK from '@/scripts/wxsdk'
export default {
  name: 'specialStockDetail',
  data(){
    return {
      formData: {
        // coVehicleSource: 2, // 1 正常 2 非一车一价 3一车一价
        // deliveryDealerCode: 'VWRDCA20231200026', 
        // deliveryDealerName: 'ID.UX 零售空间合肥包河店', 
        // materialCode: 'A24ER1C5D1P1PAZ00207', // 车辆物料
        // specialVehicleType: '40551002', // 车辆类型 字典4055
        coVehicleSource: 2, // 1 正常 2 非一车一价 3一车一价
        deliveryDealerCode: '', 
        deliveryDealerName: '', 
        materialCode: '', // 车辆物料
        specialVehicleType: '', // 车辆类型 字典4055
      },
      detailInfo: {
        materialJson: {}
      },
      records:[],
      entryType: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    dealerName() {
      const { dealers=[] } = this.userInfo
      return this.selectedShopId ? dealers.find(({ id }) => { return id === this.selectedShopId })?.name : dealers[0]?.name
    },
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
  },
  mounted(){
    const { id, entryType } = this.$route.query
    this.entryType = entryType
    id && this.getDetail(id)
    if (entryType === 'operate') {
      const config = JSON.parse(sessionStorage.getItem('specialOrderConfig'))
      // 创建时参数
      this.formData.deliveryDealerCode = String(config.deliveryStoreCode)
      this.formData.deliveryDealerName = config.deliveryStoreName
      this.formData.materialCode = config.materialCode
      this.formData.specialVehicleType = config.specialVehicleType
      this.detailInfo = config
      this.detailInfo.materialJson = {
        seriesName: config.seriesName,
        modelName: config.modelName,
        colourName: config.colourName,
        interiorName: config.interiorName,
        optionList: config.optionList
      }
    } else if (entryType === 'list') {
      const params = JSON.parse(this.$route.query.params)
      // 创建时参数
      this.formData.deliveryDealerCode = String(params.deliveryStoreCode)
      this.formData.deliveryDealerName = params.deliveryStoreName
      this.formData.materialCode = params.materialCode
      this.formData.specialVehicleType = params.specialVehicleType
      this.detailInfo = params
      this.detailInfo.materialJson = {
        seriesName: params.seriesCodeName,
        modelName: params.modelCodeName,
        colourName: params.colourCodeName,
        interiorName: params.interiorCodeName,
        optionList: [{
          dictCode: params.optionsCode,
          dictName: params.optionsCodeName,
        }]
      }
    }
    
  },
  methods:{ 
    getDetail(id) {
      specialOrderDetail({ id }).then(res=>{
        // status 状态 5. 已生效 ；10 已失效 ；15. 已关联订单
        this.detailInfo = res
      })
    },
    async downloadImg() {
      if (!this.judgePc){
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = 'Anonymous' // 如果图片需要跨域访问，需要设置该属性
        img.onload = function() {
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          const imgData = canvas.toDataURL('image/png')

          const a = document.createElement('a')
          a.href = imgData
          a.download = 'image.png'
          a.click()
        }
        img.src = this.detailInfo.qrCodeUrl
      }
    },
    create() {
      this.$dialog.confirm({
        title: '',
        message: '确认创建配置单 ？',
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            loading.showLoading()
            const res = await createSpecialOrder(this.formData)
            loading.hideLoading()
            this.$toast.success('创建成功')
            setTimeout(() => {
              // this.onback()
              this.getDetail(res)
            }, 500)
          }
        },
      })
    },
    // 取消配置单
    cancelOrder () {
      this.$dialog.confirm({
        title: '',
        message: ' 取消后配置单和二维码将失效，车辆资源会释放；确认取消 ？',
        confirmButtonColor: '#B9921A',
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            loading.showLoading()
            const res = await invalidOrder(this.detailInfo.id)
            loading.hideLoading()
            this.$toast.success('取消成功')
            setTimeout(() => {
              // this.onback()
              this.getDetail(this.detailInfo.id)
            }, 500)
          }
        },
      })
    },
    // 发送二维码
    shareImg() {
      const wechatLink = `${process.env.VUE_APP_ENV === 'uat' ? 'https://uat-oneapp-web.idux-vw.com' : 'https://m.idux-vw.com'}/WXminiBridge?path=pages%2FuniversalLinks%2FuniversalLinks&query=v%3D${this.detailInfo.coVehicleSource}%26c%3D${this.detailInfo.confOrderId}` // 分享链接
      const contentJson = {
        title: '大众汽车ID. UNYX', // 分享标题
        desc: '尊敬的客户，请点击获取您的专属下单配置', // 分享描述
        link: wechatLink,
        imgUrl: this.detailInfo.qrCodeUrl // 分享封面
      }
      wxSDK.shareAppMessage(contentJson)
    },
    goBack(){
      this.$router.go(-1)
    },
    goOrdeDetail({ id }) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
        },
      })
    },
  }
}
</script>
<style lang="less" scoped>
.car-detail{
  padding: 16px;
  margin-bottom: 100px;
  .basic-info{
    margin-bottom: 16px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
   .hr {
      padding: 4px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      &::before {
        content: ' ';
        background-color: #B9921A;
        width: 3px;
        height: 12px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .card {
      padding: 10px 4px;
      .info {
        margin-bottom: 8px;
        display: flex;
        // align-items: center;
        .label {
          min-width: 65px;
          color: rgba(13, 23, 26, 0.45);
          font-size: 13px;
        }

        .val {
          white-space: normal;
          flex: 1;
          min-width: 0;
          overflow-wrap: break-word;
          font-size: 13px;
        }
        .tag {
          justify-content: end;
          color: #B9921A;
        }
      }       
      .qrcode-box{
        background-color: #000;
        padding: 20px 10px;
        .qrcode {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          .valid-time {
            color: #fff;
            padding-left: 8px;
            font-size: 10px;
          }
        }
        
        .btns {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
          .btn {
              color: white;
      cursor: pointer;
            }
        }
      }
    }
  }
  .error-tips {
    color: red;
  }  
  .long-btn {
    flex: 2;
  }
}

</style>
